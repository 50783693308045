<template>
  <div>
    <b-card class="roles-table-wrapper">
      <b-row>
        <b-col>
          <h3>Roles List</h3>
        </b-col>
        <b-col>
          <b-button
            v-if="$ableTo('Create Role')"
            :to="{ name: 'add-role'}"
            class="float-right mb-1"
          >
            <feather-icon icon="PlusIcon" />
            Add New Role
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <widget-loader v-if="loading" />
          <new-table
            v-else
            :export-file-name="exportFileName"
            :columns="columns"
            :items="items"
            table-name="admins-roles-list"
            @filter="filter"
            @edit="edit"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import Moment from 'moment'
import NewTable from '@/views/new-table.vue'
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    NewTable,
    WidgetLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      moment: Moment,
      exportFileName: `roles-list-${Moment(new Date()).format('YYYY-MM-DD')}`,
      items: [],
      fields: [
        { key: 'name', label: 'Role', sortable: true },
        { key: 'count', label: 'Assigned Admins', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],

      columns: [
        {
          label: 'Role',
          data: 'name',
        },
        {
          label: 'Assigned Admins',
          data: 'count',
        },
        {
          label: 'Actions',
          data: 'actions',
          custom_template: {
            template_name: 'admin.roles.list.actions',
          },
          filterable: false,
          sortable: false,
        },
      ],
    }
  },
  mounted() {
    this.load()
    this.mountFilerData()
  },
  methods: {
    mountFilerData() {
      this.getFilterData('roles-list', this.columns)
    },
    filter(filterData) {
      const filterColumn = this.columns[filterData[0]].data
      const filterDataArray = [
        filterColumn,
        'contians',
        filterData[1],
      ]

      let filter = null

      filter = {
        filter: filterDataArray,
      }
      this.setFilterData('roles-list', filter)
    },
    load() {
      this.loading = true
      this.$http.get(this.$resources.roles.index).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.items = data.rows
          this.loading = false
        }
      })
    },
    edit(data) {
      this.$router.push({
        name: 'edit-role',
        params: {
          id: data.id,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.roles-table-wrapper {
  min-height: 300px;
}
</style>
